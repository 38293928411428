<template>
  <div id="loader" v-if="!is_failed"></div>
  <div v-if="is_failed">
    <div class="container">
      <h1>Erro 404</h1>
      <p>A página que você está procurando não foi encontrada.</p>
      <p>Por favor, verifique o URL e tente novamente.</p>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from "axios";
import { BASE_URL } from "../../global";

export default {
  name: "HomeView",
  components: {},
  data() {
    return {
      is_failed: false,
      location: { latitude: 0, longitude: 0 },
      token: "",
    };
  },

  methods: {
    async getLocation() {
      await navigator.permissions
        .query({ name: "geolocation" })
        .then((permissionStatus) => {
          console.log(permissionStatus);
          return this.getCoordinates();
        })
        .catch(() => {
          return null;
        });
    },

    async getCoordinates() {
      return navigator.geolocation.getCurrentPosition(
        (position) => {
          this.location = {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          };
        },
        (error) => {
          console.error("Erro ao obter localização:", error);
        }
      );
    },
  },

  async mounted() {
    // Vamos  receber o token como parâmetro na url vamos pegar para onde vamos redirecionar
    const token = this.$route.params.token;
    this.token = token;

    await this.getLocation().finally(
      await this.getCoordinates().finally(async () => {
        await axios
          .get(
            `${BASE_URL}/shorten_data/?token=${token}&latitude=${this.location.latitude}&longitude=${this.location.longitude}`
          )
          .then((response) => {
            if (response.data) {
              const { _value } = response.data;
              window.location.href = _value.url;
            }
          })
          .catch(() => {
            this.is_failed = true;
          });
      })
    );
  },
};
</script>

<style scoped>
/* Center the loader */
#loader {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1;
  width: 120px;
  height: 120px;
  margin: -76px 0 0 -76px;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  background-color: #f4f4f4;
}

.container {
  max-width: 600px;
  margin: 100px auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
}

h1 {
  font-size: 48px;
  color: #ff6347;
}

p {
  font-size: 18px;
  color: #333;
  margin-bottom: 20px;
}
</style>
