<template>
  <div id="loader" v-if="is_loading"></div>
  <div v-if="!is_loading" :style="style" class="background">
    <div class="container">
      <div class="image-container">
        <img
          :src="profile.photo"
          alt="Profile Picture"
          class="profile-img"
          v-if="!edit_image"
          @click="edit_image = true"
        />
      </div>
    </div>
    <div class="container">
      <h1>{{ profile.name }}</h1>
      <p class="pr-2" v-if="profile.is_verify">
        <i class="fa fa-check-circle" aria-hidden="true"></i>
      </p>
    </div>
    <div class="text-center container rounded-end-circle">
      <h5>{{ profile.bio }}</h5>
    </div>

    <div class="container py-4">
      <div class="row justify-content-center">
        <div class="col" v-if="profile.network.facebook">
          <a target="_blank" :href="profile.network.facebook"
            ><i
              class="fa-brands fa-facebook fs-3"
              :style="{ color: style.color }"
            ></i
          ></a>
        </div>
        <div class="col" v-if="profile.network.instagram">
          <a target="_blank" :href="profile.network.instagram"
            ><i
              class="fa-brands fa-instagram fs-3"
              :style="{ color: style.color }"
            ></i
          ></a>
        </div>

        <div class="col" v-if="profile.network.youtube">
          <a target="_blank" :href="profile.network.youtube">
            <i
              class="fa-brands fa-youtube fs-3"
              :style="{ color: style.color }"
            ></i
          ></a>
        </div>

        <div class="col" v-if="profile.network.twitter">
          <a target="_blank" :href="profile.network.twitter"
            ><i
              class="fa-brands fa-x-twitter fs-3"
              :style="{ color: style.color }"
            ></i
          ></a>
        </div>

        <div class="col" v-if="profile.network.pinterest">
          <a target="_blank" :href="profile.network.pinterest"
            ><i
              class="fa-brands fa-pinterest fs-3"
              :style="{ color: style.color }"
            ></i
          ></a>
        </div>

        <div class="col" v-if="profile.network.tiktok">
          <a target="_blank" :href="profile.network.tiktok"
            ><i
              class="fa-brands fa-tiktok fs-3"
              :style="{ color: style.color }"
            ></i
          ></a>
        </div>

        <div class="col" v-if="profile.network.linkedin">
          <a target="_blank" :href="profile.network.linkedin">
            <i
              class="fa-brands fa-linkedin fs-3"
              :style="{ color: style.color }"
            ></i
          ></a>
        </div>
      </div>
    </div>

    <div
      class="container pt-4 px-4"
      v-for="(link, index) of profile.links"
      :key="index"
    >
      <a
        :href="link.url"
        target="_blank"
        class="form-control link"
        :key="index"
        style="border-radius: 25px"
      >
        <div class="card-body text-center mt-3">
          <strong
            ><p>{{ link.label }}</p></strong
          >
        </div>
      </a>
    </div>

    <div class="container text-center fixed-bottom footer">
      <a href="https://www.flyn.one/#/" target="_blank" class="mt-3">
        <strong> <p>Crie o seu FLYN</p></strong>
      </a>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from "axios";
import { BASE_URL } from "../../global";

export default {
  name: "ProfileView",
  components: {},
  data() {
    return {
      is_failed: false,
      is_loading: true,
      token: "",
      style: {
        backgroundColor: "#f8f9fa",
        color: "#000000",
        backgroundSize:
          "cover" /* A imagem cobre a div, mantendo sua proporção */,
        backgroundPosition: "center" /* Centraliza a imagem */,
        backgroundRepeat: "no-repeat" /* Impede que a imagem se repita */,
        fontFamily: " Arial, Helvetica, sans-serif",
      },
      profile: {
        name: "",
        photo: "",
        bio: "",
        textColor: "",
        bgType: "",
        bgColor: "",
        fontColor: "",
        bgImage: null,
        is_verify: false,
        links: [],
        network: {
          facebook: "",
          instagram: "",
          pinterest: "",
          twitter: "",
          linkedin: "",
          tiktok: "",
          youtube: "",
        },
      },
    };
  },

  methods: {
    setBg() {
      if (this.profile.bgType === "color") {
        this.style.backgroundColor = this.profile.bgColor;
        this.style.backgroundImage = "";
      } else {
        this.style.backgroundImage = `url(${this.profile.bgImage})`;
        this.style.backgroundColor = "";
      }
      this.style.color = this.profile.fontColor;
    },
  },

  async mounted() {
    // Vamos  receber o token como parâmetro na url vamos pegar para onde vamos redirecionar
    const token = this.$route.params.id;
    this.token = token;

    await axios
      .get(`${BASE_URL}/public-profile/${token}`)
      .then((response) => {
        this.profile = response.data._value;
        this.setBg();
        this.is_loading = false;
      })
      .catch(() => {
        this.is_failed = true;
      });
  },
};
</script>

<style scoped>
.background {
  width: 100vw; /* Ocupa toda a largura */
  height: 100vh; /* Ocupa toda a altura */
  align-items: center;
  font-family: "Roboto", sans-serif;
}

.container {
  display: flex;
  justify-content: center;
  /* align-items: center; */
}

/* IMAGEM PERFIL */

/* Define o tamanho da div */
.image-container {
  padding-top: 5%;
  width: 15%;
  /* Defina o tamanho desejado */
  height: 15%;
  /* Defina o mesmo tamanho para altura e largura para mantê-la quadrada */
  overflow: hidden;
  /* Esconde partes da imagem que ultrapassem os limites da div */
}

/* Estiliza a imagem */
.image-container img {
  width: 100%;
  /* A imagem preenche a largura da div */
  height: 100%;
  /* A imagem preenche a altura da div */
  object-fit: cover;
  /* Faz a imagem cobrir a div, mantendo a proporção */
}

.profile-img {
  width: 20%;
  height: 20%;
  border-radius: 50%;
  overflow: hidden;
  margin: auto;
  margin-bottom: 5px;
}

@media screen and (max-width: 1000px) {
  .profile-img {
    width: 50%;
    height: 50%;
  }
  .image-container {
    width: 28%;
    /* Defina o tamanho desejado */
    height: 28%;
  }
}

/* IMAGEM PERFIL */

.link {
  text-decoration: none;
}

a {
  text-decoration: none;
}

/* FOOTER */

.footer {
  background-color: azure;
  border-radius: 15px 15px 0px 05px;
  text-decoration: none;
  width: 50%;
}

.footer a {
  text-decoration: none;
  color: dimgray;
}
</style>
